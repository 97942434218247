import React, { useContext, useEffect, useRef, useState } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";

import HelpTip, { HelpTipContainer } from "app/HelpTip";
import Icon from "app/Icon";
import SearchField from "app/SearchField";
import request from "app/http";
import _ from "app/lang";
import User from "app/models/User";
import { CloudContext, pageContext } from "app/page/ContextProvider";
import Link from "app/page/Link";
import { drawerMenuHeaderHeight, drawerWidth } from "app/theme";
import { createEvent } from "app/utils";

export const siteSelectorHeight = 42;

const SHOW_FILTER_THRESHOLD = 10;
const FILTER_XHR_DELAY = 600;

const styles = (theme: Theme) => createStyles({
    button: {
        border: "none",
        appearance: "none",
        background: "transparent",
        textAlign: "left",
        color: "inherit",
        display: "flex",
        padding: "0 12px 0 16px",
        lineHeight: siteSelectorHeight + "px",
        cursor: "pointer",
        textTransform: "none",
        borderRadius: 0,
        "&:disabled": {
            color: "inherit"
        },
        "&:focus": {
            outline: "none",
        },
        position: "absolute",
        top: drawerMenuHeaderHeight,
        left: 0,
        width: drawerWidth,
        height: siteSelectorHeight,
        borderBottom: "1px solid #e4e4e4"
    },

    text: {
        flex: "1 1 auto",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },

    icon: {
        flexShrink: 0
    },

    search: {
        padding: theme.spacing(1)
    },

    truncated: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },

    paper: {
        display: "flex",
        flexDirection: "column",
        width: theme.spacing(30),
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    },

    header: {
        flexShrink: 0,
        flexGrow: 0
    },

    footer: {
        flexShrink: 0,
        flexGrow: 0
    },

    body: {
        flexGrow: 1,
        flexShrink: 1,
        overflow: "auto",
        "-ms-overflow-style": "-ms-autohiding-scrollbar",
        // maxHeight: theme.spacing(60)
    },

    actions: {
        display: "flex",
        justifyContent: "flex-end",
        padding: theme.spacing(1)
    },

    labels: (props: Props) => ({
        width: "100%",
        marginLeft: 0,
        cursor: props.disabled ? "revert" : undefined,
    }),

    labelTypography: {
        marginBottom: 6,
    },

    helperTextLabel: {
        display: "block"
    }
});

export type SiteSelectorClasses = Partial<WithStyles<typeof styles>["classes"]>;

export interface SiteGroupingInfo {
    id: string;
    name: string;
}

export interface SiteGroupingResponse {
    userCloudFilter: SiteGroupingInfo;
    siteGroupings: SiteGroupingInfo[];
}

interface Props {
    placeholder?: string;
    disabled?: boolean;
    label?: string;
    showLabel?: boolean;
    onChange?: (site: SiteGroupingInfo) => void;
    error?: boolean;
    helperText?: string;
    selected: SiteGroupingInfo | null;
    setSelected: (site: SiteGroupingInfo | null) => void;
    siteGroupings: SiteGroupingInfo[] | undefined;
    setSiteGroupings: (siteGroupings: SiteGroupingInfo[] | undefined) => void;
    fetchSiteGrouping: (filter?: string) => void;
    backendFilter?: boolean;
}

let fetchTimer: number;

function GroupingList(props: Props & WithStyles<typeof styles>) {
    const {
        classes,
        placeholder = _("Choose a Group..."),
        disabled = false,
        label = _("Site Grouping"),
        showLabel = false
    } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filter, setFilter] = useState<string | undefined>(undefined);
    const anchorElement = useRef<HTMLButtonElement>(null);
    const buttonText = (props.selected) ? props.selected.name : placeholder;
    const buttonIcon = isOpen ? "arrow_drop_up" : "arrow_drop_down";
    const context = useContext(pageContext) as CloudContext;
    const user = new User(useContext(pageContext));

    useEffect(() => {
        if (props.siteGroupings && props.siteGroupings.length > 0) {
            // hack to reload the popover or else it site list may be positioned out of screen
            window.dispatchEvent(createEvent("resize"));
        }
    }, [props.siteGroupings && props.siteGroupings.length > 0]);

    useEffect(() => {
        if (props.siteGroupings) {
            let siteToSelect = null;

            siteToSelect = props.siteGroupings?.find(s => {
                if (props.selected === null) {
                    return false;
                }

                return s.id === props.selected.id;
            });

            props.setSelected(siteToSelect ?? null);
        }
    }, [props.selected, props.siteGroupings?.map(s => s.id).join(",")]);

    const toggleMenu = () => {
        if (!isOpen) {
            props.fetchSiteGrouping(filter);
        }

        setIsOpen(!isOpen);
    };

    const searchRef = useRef<HTMLDivElement>(null);

    let searchField = null;

    if (props.siteGroupings && props.siteGroupings.length >= SHOW_FILTER_THRESHOLD || props.backendFilter) {
        const handleGroupChange = (value: string) => {
            const _filter = value.toLowerCase();

            setFilter(_filter);
            window.clearTimeout(fetchTimer);

            if (props.backendFilter) {
                props.setSiteGroupings(undefined);
                fetchTimer = window.setTimeout(() => props.fetchSiteGrouping(_filter), FILTER_XHR_DELAY);
            }
        };

        searchField = (
            <div className={classes.header} tabIndex={-1} ref={searchRef}>
                <SearchField
                    className={classes.search}
                    onChange={handleGroupChange}
                    placeholder={_("Grouping")}
                    initialValue={filter}
                />
                <Divider />
            </div>
        );
    }

    let visiblesiteGroupings: SiteGroupingInfo[] = [];

    if (props.siteGroupings) {
        visiblesiteGroupings = props.siteGroupings;
    }

    if (filter && filter.length > 0) {
        visiblesiteGroupings = visiblesiteGroupings.filter(
            siteFilter => siteFilter.name.toLowerCase().includes(filter));
    }

    const handleCreateSiteGroupingClick = () => setIsOpen(false);

    let notice = null;

    if (!props.siteGroupings) {
        notice = (
            <MenuItem disabled>{_("Searching")}</MenuItem>
        );
    } else if (visiblesiteGroupings.length === 0) {
        if (filter) {
            notice = (
                <MenuItem disabled>{_("No matching sites found.")}</MenuItem>
            );
        } else {
            notice = (
                <Link to="/v2/cloud/:cloudId/site-filter/add" onClick={handleCreateSiteGroupingClick}>
                    <MenuItem >
                        <HelpTipContainer>
                            {_("Please create a Site Grouping.")}
                            <HelpTip
                                title={_("A “Site Grouping” is a logical filter of sites.")}
                                variant="default"
                                filled
                            />
                        </HelpTipContainer>
                    </MenuItem>
                </Link>
            );
        }
    } else if (props.selected !== null) {
        notice = (
            <Button onClick={() => removeCloudFilter()}> {_("Reset Site Grouping")}</Button>
        );
    }

    const removeCloudFilter = () => {
        request({
            url: "/apiv2/cloud/:cloudId/site-filters-select",
            method: "DELETE"
        }).then((data) => {
            window.location.replace("/cloud/" + context.cloud.id + "/dashboard");
        });
    };

    const handleClick = (siteFilter: SiteGroupingInfo) => {
        request({
            url: "/apiv2/cloud/:cloudId/site-filters-select",
            method: "POST",
            data: {
                siteFilterId: siteFilter.id
            }
        }).then(() => {
            props.setSelected(siteFilter);
            window.location.replace("/cloud/" + context.cloud.id + "/dashboard");
        });

        setIsOpen(false);
    };

    const handleEntered = () => {
        if (searchRef.current !== null) {
            const input = searchRef.current.querySelector("input");

            if (input !== null) {
                input.focus();
            }
        }
    };

    let createNewSiteGroupingFooter;

    if (user.hasPermission("manage_sites")) {
        createNewSiteGroupingFooter = (
            <div className={classes.footer}>
                <Divider />
                <div className={classes.actions}>
                    <Link to="/v2/cloud/:cloudId/site-filter/add">
                        <Button variant="text" color="primary" onClick={handleCreateSiteGroupingClick}>
                            {_("Create a new Site Grouping")}
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <>
            <FormGroup row>
                <FormControlLabel
                    labelPlacement="start"
                    label={showLabel ? label : null}
                    classes={{
                        root: classes.labels,
                        label: classes.labelTypography
                    }}
                    control={(
                        <Box>
                            <Button
                                variant="text"
                                disabled={disabled}
                                className={classes.button}
                                onClick={toggleMenu}
                                disableRipple={true}
                                ref={anchorElement}
                            >
                                <div className={classes.text}>{buttonText}</div>
                                <Icon id={buttonIcon} className={classes.icon} />
                            </Button>
                            {props.error && (
                                <FormLabel component="span" error className={classes.helperTextLabel}>
                                    {props.helperText}
                                </FormLabel>
                            )}
                        </Box>
                    )}
                />
                <Popover
                    anchorEl={anchorElement.current}
                    open={isOpen}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    onEntered={handleEntered}
                    onExited={() => null}
                    onClose={toggleMenu}
                    classes={{ paper: classes.paper }}
                >
                    {searchField}
                    {notice}
                    <div className={classes.body}>
                        {visiblesiteGroupings.map(site => (
                            <MenuItem key={site.id}
                                onClick={() => handleClick(site)}
                                selected={props.selected !== null && site.id === props.selected.id}
                            >
                                <span className={classes.truncated}>{site.name}</span>
                            </MenuItem>
                        ))}
                    </div>
                    {createNewSiteGroupingFooter}
                </Popover>
            </FormGroup>
        </>
    );
}

export default withStyles(styles)(GroupingList);
